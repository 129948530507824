import React from 'react';
// import Typewriter from 'typewriter-effect';
import Myphoto from './images/myphoto.png'; // Adjust the path to your image file

const  Intro=()=>{
    return(
        <div className="section intro">
            <div className="container-full-width">
              
            
                <div className='row align-items-center'>
                    <div className='intro-section-wrapper'>
                        <div className="photo"> <img id="myphoto" width="40" src={Myphoto} alt="My Image" /></div>
                        <div className='right-text-box'>
                            <h2 className='name'>SUDARSHAN <span style={{color:"#c00"}}>GIRI</span></h2>
                            <h2 className='designation pb-5'>FULL-STACK SOFTWARE ENGINEER</h2>
                            <div className="social-media-icons animate_animated animate__zoomIn">
                                <a className="git" href="https://github.com/SudarshanGiri" rel="noreferrer"  target="_blank"><i className="fab fa-github" aria-hidden="true"></i></a>
                                <a className="linkedin" href="https://www.linkedin.com/in/sudarshan-giri-ba453b152/" target="_blank" rel="noreferrer" > <i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
            
                            </div>

                        </div>

                        
                      
                    </div>
                    
                </div>
                                
                            
                        
                 
                    
            </div>

        </div>
        
    )



}

export default Intro;