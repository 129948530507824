import React from 'react';
import './sass/style.scss'; // Import a .scss or .sass file
import { Routes, Route } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

import Footer from './components/Footer';
import Home from './pages/Homepage/Home';
import About from './components/About';
import Services from './pages/Services/Services';
import Resume from './pages/Resume/Resume';
import Contact from './pages/Contact/Contact';
import Projects from './pages/Projects/Projects';
import Skills from './pages/Skills/Skills';
import ProjectDetail from './pages/ProjectDetail/ProjectDetail';
import ServiceDetail from './pages/ServiceDetail/ServiceDetail';
import NavBar from './components/NavBar/NavBar';


function App() {
  return (
    
    <div className="App">
      <NavBar />
      <Routes>
        <Route exact={true} path="/" element={<Home/>} />
        <Route exact={true} path="/about" element={About} />
        <Route exact={true} path="/services" element={<Services />} />
        <Route exact={true} path="/contact" element={<Contact/>} />
        <Route exact={true} path="/projects" element={<Projects/>} />
        <Route  path="/projects/:id" element={<ProjectDetail/>} />
        <Route  path="/services/:id" element={<ServiceDetail/>} />
        <Route exact={true} path="/skills" element={Skills} />
        <Route exact={true} path="/resume" element={<Resume/>} />

        <Route to ="/" />
      </Routes>
      <Footer />

        
        
    </div>
  );
}

export default App;
