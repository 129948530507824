import React from "react";

const About = () =>{
    return(
        <>
            <div class="top-banner mb-5">
                <div className="container">
                    <h1 className="text-center">ABOUT</h1>
                </div>
                    
            </div>
            <div className="container">
                <h1> Welcome About Page</h1>

            </div>
           
        </>

    );
}

export default About;