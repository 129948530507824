import React from "react";
import Intro from './Intro';
import Marquee from "react-fast-marquee";
import   image1 from "./images/keyboard.jpg";
import   mountain from "./images/mountain.jpeg";
import ProjectsData from '../Projects/ProjectsData';
import Clients from "./Clients";
import { Parallax } from "react-parallax";



const techStackTerms = [
    "JavaScript",
    "React",
    "Node.js",
    "Python",
    "Angular",
    "Vue.js",
    "HTML5",
    "CSS3",
    "Sass",
    "MongoDB",
    "SQL",
    "Express.js",
    "Django",
    "Ruby",
    "C#",
  
  ];
  const techStackTerms2 = [
    "REST API",
    "GraphQL",
    "Webpack",
    "Redux",
    "Vue.js",
    "TypeScript",
    "SASS/SCSS",
    "Firebase",
    "TensorFlow",
    "PyTorch",
    "D3.js",
    "Bootstrap",
    "Material-UI",
    "Webpack",
    "Elasticsearch",
    
  ]
  const techStackTerms3 = [
    "ASP.NET",
    "Spring Boot",
    "PHP",
    "Laravel",
    "AWS",
    "Docker",
    "Kubernetes",
    "Git",
    "Jenkins",
    "CI/CD",
    "Kibana",
    "Redis",
    "PostgreSQL",
    "MySQL",
  
  ]
  

const Home = () =>{
   
    
    return(
        <>
             
            <section id="intro-wrapper" className="">
                <Intro />
            </section>
            <div className="section section-recent-projects border-top">
                <div className="container-full-width">
                    <span className="section-small-heading">  <i class="fa-solid fa-code"></i> WORK.</span>
                    <h2 className="section-title">RECENT PROJECTS.</h2>
                    <div className="projects-items-wrapper">
                        <ProjectsData max_items="6" />

                    </div>

                </div>

            </div>
            <div className="section section-marquee-text border-top-bottom">
                <div className="container-full-width">
                    <span className="section-small-heading">  <i class="fa-solid fa-code"></i> SPECIALITIES.</span>

                    <h2 className="section-title">TECH STACKS.</h2>

                </div>
                <div className="border-top-bottom py-5 mx-5">
                    <Marquee direction="right" speed="50">
                        {techStackTerms.map((term, index) => (
                            <div key={index} className="text-item">{techStackTerms.join(" / ")}</div>
                        ))}
                    </Marquee>
                    <Marquee direction="left"  speed="60">
                        {techStackTerms.map((term, index) => (
                            <div key={index} className="text-item">{techStackTerms2.join(" / ")}</div>
                        ))}
                    </Marquee>
                    <Marquee direction="right"  speed="50">
                        {techStackTerms.map((term, index) => (
                            <div key={index} className="text-item">{techStackTerms3.join(" / ")}</div>
                        ))}
                    </Marquee>
                </div>
            </div>
            <Parallax className="parallax-home-wrapper" bgImage={image1} strength={500}>
               <div className="parallax-home" style={{ height: 500 }}>
                   <div className="inner-text">
                        <div className="container-full-width col-lg-8">
                            <span className="section-small-heading text-white">  <i class="fa-solid fa-code"></i> GET IN TOUCH.</span>
                        </div>

                       <div className="container-full-width col-lg-8">
                       I don't just CODE but live for it.
                       </div>

                       <div className="container-full-width col-lg-8 mt-5">
                            <span className="section-small-heading text-white text-decoration-underline"> CONTACT: sudarshanhang@gmail.com</span>
                        </div>
                   </div>
               
               </div>
           </Parallax>


            <div id="section-clients" className="section section-clients ">
                <div className="container-full-width">
                    <span className="section-small-heading">  <i class="fa-solid fa-code"></i> CLIENTS.</span>
                    <h2 className="section-title">
                        CLIENTS I HAVE WORKED WITH.
                    </h2>
                    <Clients />

                </div>
            </div>

            <Parallax className="parallax-home-wrapper" bgImage={mountain} strength={500}>
               <div className="parallax-home" style={{ height: 500 }}>
                   <div className="inner-text">
                        <div className="container-full-width col-lg-8">
                            <span className="section-small-heading text-white">  <i class="fa-solid fa-code"></i> COLLABORATE</span>
                        </div>

                       <div className="container-full-width col-lg-8">
                       IF YOU CAN IMAGINE IT, WE CAN BUILD IT.
                       </div>
                   </div>
               
               </div>
           </Parallax>
           
        </>

    );
}

export default Home;